<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>{{ $i18n.translate("Organization") }} - {{ organizationName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-card-text class="col-4 mt-8">
        <v-select
          dense
          outlined
          v-model="relatedOrganizationId"
          :label="$i18n.translate('Organizations')"
          :items="filterByRelatedOrganization"
          item-value="id"
          item-text="name"
          @change="onSubmitSearch"
        ></v-select>
      </v-card-text>
      <v-form @submit.stop.prevent="onSubmitSearch">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Filter"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClearSearch"
        ></v-text-field>
      </v-form>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <AlertDisplay ref="alertRef" />
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1"
        item-key="id"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        :server-items-length="total"
        :options.sync="options"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status.name)" class="ma-2" text-color="black">{{
            item.status.description
          }}</v-chip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-tooltip bottom v-if="item.status.name === 'ENR'">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="mr-2" icon @click="onResetPassword(item)">
                <v-icon>mdi-key-variant</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Reset Password") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.status.name === 'ENR'">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="mr-2" icon @click="onCancelReps(item)">
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Cancel Registration") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.status.name === 'PRE'">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="ma-2" icon color="blue lighten-2" @click="onApprove(item)">
                <v-icon>mdi-thumb-up</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Approve") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.status.name === 'PRE'">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" class="ma-2" icon color="red lighten-2" @click="onReject(item)">
                <v-icon>mdi-thumb-down</v-icon>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Reject") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <v-dialog v-if="dialog" v-model="dialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="headline">{{ selectedActionHeader }}</v-card-title>
          <v-card-text>
            <p v-html="selectedActionDescription"></p>
            <v-row v-if="selectedActionType === actionTypes.REJECTING">
              <v-col cols="12">
                <v-textarea
                  label="Comments"
                  name="reason"
                  id="reason"
                  v-model="rejectReason"
                  :rules="rules.reason"
                  :error-messages="$error.getValidationError(errors, 'reason')"
                  @input="$error.clearValidationError(errors, 'reason')"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row v-if="selectedActionType === actionTypes.CANCELLING">
              <v-col cols="12">
                <RejectionReasonField
                  :selectedRejectReasonValue="selectedRejectParticipantReason"
                  :rejectReason="rejectParticipantReason"
                  :allowOther="true"
                  @update:selectedRejectReason="updateRejectionReason"
                  rejectionType="PARTICIPANT"
              /></v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onCancel()">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn
              :disabled="selectedActionType === actionTypes.CANCELLING && validRejectParticipantReason"
              color="primary"
              @click="onSubmit()"
              :loading="loading"
              >Agree</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import ApiError from "@/gapp-components/components/display/ApiError.vue";
import RejectionReasonField from "@/gapp-components/components/fields/RejectionReasonField.vue";
import AlertDisplay from "@/gapp-components/components/display/AlertDisplay.vue";

export default {
  components: { ApiError, RejectionReasonField, AlertDisplay },
  name: "MyCompanyManagement",
  metaInfo: {
    title: "Dealer Management"
  },

  data: function() {
    return {
      loading: false,
      search: "",
      headers: [],
      items: [],
      itemSelected: { user: {} },
      errors: {},
      rules: {
        reason: [v => !!v || "Reason is required"]
      },

      rejectParticipantReason: "",
      selectedRejectParticipantReason: "",
      relatedOrganizationId: null,
      filterByRelatedOrganization: [],

      dialog: false,
      rejectReason: undefined,
      rejectReasonValid: false,
      actionTypes: {
        APPROVING: "approving",
        CANCELLING: "cancelling",
        REJECTING: "rejecting",
        RESET_PASSWORD: "reset_password"
      },
      selectedActionType: null,

      total: 0,
      options: {
        itemsPerPage: 10
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },
  methods: {
    onResetPassword(item) {
      this.itemSelected = item;
      this.selectedActionType = this.actionTypes.RESET_PASSWORD;
      this.dialog = true;
    },

    onApprove(item) {
      this.itemSelected = item;
      this.selectedActionType = this.actionTypes.APPROVING;
      this.dialog = true;
    },

    onReject(item) {
      this.itemSelected = item;
      this.selectedActionType = this.actionTypes.REJECTING;
      this.dialog = true;
    },

    onCancelReps(item) {
      this.itemSelected = item;
      this.selectedActionType = this.actionTypes.CANCELLING;
      this.dialog = true;
    },

    onSubmit() {
      this.$refs.alertRef.resetAlert();
      switch (this.selectedActionType) {
        case this.actionTypes.APPROVING:
          return this.approve();
        case this.actionTypes.CANCELLING:
          return this.cancelReps();
        case this.actionTypes.REJECTING:
          return this.reject();
        case this.actionTypes.RESET_PASSWORD:
          return this.resetPassword();
      }
    },

    approve() {
      this.loading = true;

      let postForm = {
        approvedUser: this.selectedParticipant.user,
        emailTemplateParticipantForm: {
          emailTemplateKey: "SALES_REWARDS_JOIN"
        }
      };
      return this.$api
        .post("/api/participants/approve/" + this.itemSelected.id, postForm)
        .then(() => {
          this.onCancel();
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    reject() {
      this.loading = true;

      let postForm = {};
      postForm.reason = this.rejectReason;
      postForm.emailTemplateParticipantForm = { emailTemplateKey: "DEALERSHIP_REJECTED" };

      return this.$api
        .post(`/api/participants/reject/${this.itemSelected.id}`, postForm)
        .then(() => {
          this.onCancel();
          this.fetchData();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancelReps() {
      this.loading = true;

      let patchForm = {};
      patchForm.cancelledReason =
        this.selectedRejectParticipantReason == "Other"
          ? this.rejectParticipantReason
          : this.selectedRejectParticipantReason;
      patchForm.status = { name: "PRE_CAN" };

      return this.$api
        .patch("/api/participants/" + this.itemSelected.id, patchForm)
        .then(() => {
          this.onCancel();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.fetchData();
        });
    },

    resetPassword() {
      this.loading = true;

      let postForm = {};
      postForm.username = this.itemSelected.user.username;

      return this.$api
        .post("/api/users/resetPassword", postForm)
        .then(() => {
          this.onCancel();
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.alertRef.success("Reset Password Successfully");
          });
        });
    },

    onClearSearch() {
      this.search = "";
      this.fetchData();
    },

    onSubmitSearch() {
      this.options.page = 0;
      this.fetchData();
    },

    updateRejectionReason({ selectedRejectReasonValue, rejectReason }) {
      this.selectedRejectParticipantReason = selectedRejectReasonValue;
      this.rejectParticipantReason = rejectReason;
    },

    fetchRelatedOrganizations() {
      this.$api
        .post("/api/relatedOrganizations/search", { participant: { id: this.selectedParticipant.id } })
        .then(({ data }) => {
          const primaryOrganization = {
            id: this.selectedParticipant.organization.id,
            name: this.selectedParticipant.organization.name
          };
          this.filterByRelatedOrganization = [
            primaryOrganization,
            ...data.content.map(item => ({
              id: item.relatedOrganization.id,
              name: item.relatedOrganization.name
            }))
          ];
          this.relatedOrganizationId = primaryOrganization.id;
        });
    },

    async fetchData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {};
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      if (this.relatedOrganizationId) {
        filters.organization = { id: this.relatedOrganizationId };
      } else {
        filters.organization = { id: this.selectedParticipant.organization.id };
      }
      filters.statuses = [{ name: "ENR" }, { name: "PRE" }];

      this.$api
        .post(
          "/api/participants/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onCancel() {
      this.dialog = false;
      this.rejectReasonValid = false;
      this.selectedRejectParticipantReason = "";
      this.rejectParticipantReason = "";
      this.search = "";
      this.errors = {};
    },

    getStatusColor(status) {
      switch (status) {
        case "CAN":
          return "red";
        case "ENR":
          return "primary";
        case "PRE":
          return "yellow";
      }
    }
  },
  mounted() {
    this.headers = [
      { text: this.$i18n.translate("Participant") + " #", value: "participantKey" },
      { text: "First Name", value: "user.firstName" },
      { text: "Last Name", value: "user.lastName" },
      { text: "Email Address", value: "user.username" },
      { text: this.$i18n.translate("Participant Type"), value: "participantType.name" },
      { text: "Status", value: "status", align: "center" },
      { text: "Action", value: "action", sortable: false, align: "center" }
    ];
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Admin" },
      {
        text: "My Company Management"
      }
    ]);
    this.fetchRelatedOrganizations();
  },
  computed: {
    ...mapGetters(["selectedParticipant"]),

    validRejectParticipantReason() {
      if (this.selectedRejectParticipantReason != "" && this.selectedRejectParticipantReason.length > 0) {
        if (this.selectedRejectParticipantReason == "Other" && this.rejectParticipantReason != "") {
          return false;
        } else {
          if (this.selectedRejectParticipantReason != "Other") {
            return false;
          }
        }
      }
      return true;
    },
    hasFilters() {
      if ((this.search && this.search.length > 0) || this.relatedOrganizationId !== null) {
        return true;
      }
      return false;
    },
    organizationName() {
      const selected = this.filterByRelatedOrganization.find(item => item.id === this.relatedOrganizationId);
      return selected ? selected.name : "";
    },

    selectedActionHeader() {
      let header = "";
      switch (this.selectedActionType) {
        case this.actionTypes.APPROVING:
          header = "Approve Registration?";
          break;
        case this.actionTypes.CANCELLING:
          header = "Are you sure you want to cancel Reps?";
          break;
        case this.actionTypes.REJECTING:
          header = "Reject Registration?";
          break;
        case this.actionTypes.RESET_PASSWORD:
          header = "Are you sure you want to reset password?";
          break;
      }

      return header;
    },

    selectedActionDescription() {
      let description = "";
      switch (this.selectedActionType) {
        case this.actionTypes.APPROVING:
          description = `The registration request for <b>${this.itemSelected.fullName} </b> will be Approved. An email to <b> ${this.itemSelected.email1}</b> will be sent with login instructions.`;
          break;
        case this.actionTypes.CANCELLING:
          description =
            "This means sending an email notification to the participant email address notifying them of the cancellation.";
          break;
        case this.actionTypes.REJECTING:
          description = `The registration request for <b>${this.itemSelected.fullName}</b> will be Rejected. An email to <b>${this.itemSelected.email1}</b> will be sent with any comments you provide below.`;
          break;
        case this.actionTypes.RESET_PASSWORD:
          description = `${this.itemSelected.user.username} will be sent an email containing a temporary password, along with instructions to create a new one`;
          break;
      }

      return description;
    }
  }
};
</script>
